<template>
  <div class="content-wrapper">
    <Breadcrumb style="margin-bottom: 14px;">
      <BreadcrumbItem to="/dmdatasource">{{ $t('cha-xun-pei-zhi') }}</BreadcrumbItem>
      <BreadcrumbItem>{{ $t('xiu-gai-can-shu-pei-zhi') }}</BreadcrumbItem>
    </Breadcrumb>
    <query-header :handle-query="handleQuery" :handle-refresh="getDsParams" :showSelect="false"
                  :showAddBtn="false" :query="queryForm" :placeholder="$t('qing-shu-ru-can-shu-ming-cheng-cha-xun')">
      <a-button type="primary" @click="handleShowConfirmEditParams(true)">{{ $t('sheng-xiao-pei-zhi') }}</a-button>
    </query-header>
    <a-table :columns="configColumns" :data-source="configData" :locale="{emptyText: $t('zan-wu-shu-ju')}"
             :rowKey="record => record.id"
             size="small">
      <template slot="configName" slot-scope="record">
        <div class="config-name">
          <div class="name" :style="`color: ${record.needCreated ? '#ff6e0d' : ''}`">
            {{ record.configName }}
            {{record.needCreated ? $t('dai-chuang-jian') : ''}}
          </div>
          <div class="tips">
            <a-tooltip placement="right">
              <template slot="title">
                <span>{{ record.description }}</span>
              </template>
              <a class="config-desc-tip">
                <a-icon type="info-circle"/>
              </a>
            </a-tooltip>
          </div>
        </div>
      </template>
      <template slot="defaultValue" slot-scope="record">
        {{ record.defaultValue || '-' }}
      </template>
      <template slot="configValue" slot-scope="record">
        {{ record.configValue || '-' }}
      </template>
      <template slot="editParam" slot-scope="record">
        <div>
          <div v-if="!record.readOnly" style="display: flex;justify-content: space-between;margin-right: 10px;">
                      <span
                        style="margin-right: 16px;display: inline-block;">{{
                          record.currentCount
                        }}</span><span>
                                    <a-popover v-model="record.visible" transfer trigger="click"
                                               @on-popper-show="handlePopShow(record)"
                                               placement="right" width="250">
                                   <a-icon style="cursor: pointer" type="edit"/>
                                <div slot="content">
                                    <p style="font-size: 12px;margin-bottom: 10px">{{ $t('xiu-gai-can-shu-wei') }}</p>
                                    <p style="margin-bottom: 10px"><a-input size="small"
                                                                            style="width: 200px"
                                                                            v-model="currentValue"/></p>
                                    <p><a-button style="margin-right: 5px" type="primary"
                                                 size="small"
                                                 @click="handleEditCurrent(configData,record)">{{ $t('que-ding') }}</a-button></p>
                                </div>
                            </a-popover>
                                <a-tooltip transfer style="margin-left: 5px"
                                           placement="right"
                                           v-if="record.currentCount">
                                  <template slot="title">
                                      <span>{{ $t('che-xiao') }}</span>
                                  </template>
                                  <a style="font-size: 16px;"
                                     @click="handleCancelEdit(configData,record)"><Icon
                                    type="md-undo"/></a></a-tooltip></span>
          </div>
          <div v-if="record.readOnly">
            {{ $t('zhi-du-can-shu') }}
          </div>
        </div>
      </template>
      <template slot="configGroup" slot-scope="record">
        <div>
          <span class="config-label"
                :style="`color:${colors[record.configGroup || record.userConfigTagType]};border-color:${colors[record.configGroup||record.userConfigTagType]}`">
            {{ record.configGroup || record.userConfigTagType }}
          </span>
        </div>
      </template>
      <template #valueAdvance="record">
        {{ record.valueAdvance || record.valueRange }}
      </template>
    </a-table>
    <a-modal
      v-if="showParamsEditConfirm"
      v-model="showParamsEditConfirm"
      :title="$t('que-ding-yao-sheng-xiao-yi-xia-pei-zhi-ma')"
      footer-hide
      :width="800"
    >
      <div style="overflow: auto">
        <div id="elem"></div>
        <a-table border size="small" :columns="editColumns" :data-source="editedParams">
          <template #configName="record">
            <div class="config-name">
              <div class="name" :style="`color: ${record.needCreated ? '#ff6e0d' : ''}`">
                {{ record.configName }}
                {{record.needCreated ? $t('dai-chuang-jian') : ''}}
              </div>
            </div>
          </template>
        </a-table>
        <div class="footer" style="margin-top: 20px">
          <a-button type="primary" @click="handleConfirmEditParams">{{ $t('que-ren') }}</a-button>
          <a-button @click="handleCancelEditParams">{{ $t('qu-xiao') }}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import QueryHeader from '../../components/QueryHeader';
import { PARAMS_CONFIG } from '../../../../const';

export default {
  components: {
    QueryHeader
  },
  mounted() {
    this.dataSourceId = this.$route.params.id;
    if (this.$route.name === 'User_Config') {
      this.type = 'user';
    }
    this.getDsParams();
  },
  data() {
    return {
      type: 'ds',
      dataSourceId: 0,
      queryForm: {
        param: '',
        text: ''
      },
      configColumns: [
        {
          title: this.$t('can-shu-ming-cheng'),
          key: 'configName',
          scopedSlots: { customRender: 'configName' },
          width: 220
        },
        {
          title: this.$t('can-shu-mo-ren-zhi'),
          key: 'defaultValue',
          width: 220,
          scopedSlots: { customRender: 'defaultValue' }
        },
        {
          title: this.$t('dang-qian-yun-hang-zhi'),
          key: 'configValue',
          width: 220,
          scopedSlots: { customRender: 'configValue' }
        },
        {
          title: this.$t('xiu-gai-hou-de-can-shu-zhi'),
          key: 'editParam',
          width: 300,
          scopedSlots: { customRender: 'editParam' }
        },
        {
          title: this.$t('ke-xiu-gai-fan-wei'),
          key: 'valueAdvance',
          width: 300,
          scopedSlots: { customRender: 'valueAdvance' }
        },
        {
          title: this.$t('biao-qian'),
          key: 'configGroup',
          scopedSlots: { customRender: 'configGroup' }
        }
      ],
      trueData: [],
      configData: [],
      currentValue: '',
      editedParams: [],
      showParamsEditConfirm: false,
      editColumns: [
        {
          title: this.$t('can-shu-ming'),
          scopedSlots: { customRender: 'configName' },
          width: 300
        },
        {
          title: this.$t('dang-qian-yun-hang-zhi'),
          dataIndex: 'configValue',
          key: 'configValue'
        },
        {
          title: this.$t('xiu-gai-hou-de-zhi'),
          dataIndex: 'currentCount',
          key: 'currentCount'
        }
      ],
      paramsDataMap: {},
      colors: {
        OPTIONS: '#56bc76',
        GENERAL: '#a88108',
        COMMON: '#a88108',
        CLOUD: '#32adfa',
        GH_OST: '#a88108'
      }
    };
  },
  methods: {
    handleQuery() {
      this.configData = [];
      this.trueData.forEach((data) => {
        if (data.configName.indexOf(this.queryForm.text) > -1) {
          this.configData.push(data);
        }
      });
    },
    async getDsParams() {
      const res = await this.$services[PARAMS_CONFIG[this.type].get]({ data: { dataSourceId: this.dataSourceId } });
      if (res.success) {
        this.queryForm.text = '';
        this.configData = res.data;
        this.trueData = res.data;
      }
    },
    handleCancelEdit(data, row) {
      data.map((item) => {
        if (item.configName === row.configName) {
          item.currentCount = '';
        }
        return null;
      });
      this.configData = Object.assign([], data);
    },
    handleEditCurrent(data, row) {
      data.map((item) => {
        if (item.configName === row.configName) {
          item.currentCount = this.currentValue;
        }
        return null;
      });
      this.configData = Object.assign([], data);
      // row.currentCount = this.currentValue;
      this.currentValue = '';
      row.visible = false;
    },
    handlePopShow(row) {
      this.currentValue = row.count;
    },
    handleShowConfirmEditParams() {
      this.editedParams = [];
      this.showParamsEditConfirm = true;
      Object.values(this.configData)
        .forEach((item) => {
          if (item.needCreated || ((item.currentCount === 0 || item.currentCount) && item.currentCount !== item.configValue)) {
            this.editedParams.push(item);
          }
        });
    },
    handleConfirmEditParams() {
      this.showParamsEditConfirm = false;
      if (this.editedParams.length === 0) {
        this.$Modal.warning({
          title: this.$t('cao-zuo-yi-chang'),
          content: this.$t('qing-xiu-gai-xu-yao-sheng-xiao-de-can-shu')
        });
        return;
      }
      const data = {};
      const needCreateConfigs = {};
      this.editedParams.forEach((item) => {
        if (item.needCreated) {
          needCreateConfigs[item.configName] = item.currentCount || item.defaultValue;
        } else {
          data[item.configName] = item.currentCount;
        }
      });
      // console.log(needCreateConfigs);
      this.$services[PARAMS_CONFIG[this.type].edit]({
        data: this.type === 'user' ? {
          updateConfigs: data,
          needCreateConfigs
        } : {
          dataSourceId: this.dataSourceId,
          updateConfigMap: data,
          needCreateConfigMap: needCreateConfigs
        }
      })
        .then((res) => {
          if (res.code === '1') {
            this.paramsDataMap = {};
            this.getDsParams();
            this.editedParams = [];
            this.$Message.success(this.$t('pei-zhi-yi-xiu-gai-cheng-gong'));
          }
        });
    },
    handleCancelEditParams() {
      this.editedParams = [];
      this.showParamsEditConfirm = false;
      this.showParamsEdit = false;
    }
  }
};
</script>
<style lang="less">
//.config-name {
//  position: relative;
//  .config-desc-tip {
//    position: absolute;
//    right: 20px;
//    top: 0;
//  }
//}
.config-name {
  display: flex;
  justify-content: space-between;
}

.config-label {
  display: inline-block;
  padding: 2px 6px;
  border: 1px solid;
}
</style>
