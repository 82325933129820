<template>
  <Params />
</template>
<script>
import Params from './components/Params';

export default {
  components: { Params }
};
</script>
